select.input-lg {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /* no standardized syntax available, no ie-friendly solution available */
  }
  
  select + i.fa {
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    /* this is so when you click on the chevron, your click actually goes on the dropdown menu */
    pointer-events: none;
    /* everything after this is just to cover up the original arrow */
    /* (for browsers that don't support the syntax used above) */
    background-color: #fff;
    padding-right: 5px;
  }
  